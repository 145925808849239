import React from 'react'

import CallToAction from 'src/components/CallToAction'
import { SideBar, ContentWrapper } from 'src/layouts/BaseLayout/style'
import SectionTopics from 'components/SectionTopics'

import Seguros from 'src/assets/images/temps/table-seguros.png'

export const Content = () => {
  return (
    <ContentWrapper>
      <section className='pt-5 pb-lg-5 bg-white'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col col-lg-8'>
              <h3>Segurança é essencial para o seu negócio</h3>
              <p>Cuidar da segurança da sua empresa é, além de proteger o seu patrimônio material, garantir tranquilidade pra você e para as pessoas que fazem o seu negócio acontecer. Conte com o seu banco parceiro para ter acesso aos melhores seguros para Pessoa Jurídica.</p>
              <h4>Conheça os nossos seguros para Pessoa Jurídica</h4>
              <SectionTopics title='Vida em Grupo' styles='fs-md-30'>
                <ul className='list-unstyled'>
                  <li>A partir de 100 vidas: Proteção financeira para funcionários, sócios, executivos e seus beneficiários, atendendo às exigências das convenções coletivas. Indenização liberada independentemente do inventário e livre de impostos. Investimento de baixo custo para a empresa e que protege e valoriza o funcionário, refletindo nos resultados e produtividade da empresa.</li>
                </ul>
              </SectionTopics>
              <SectionTopics title='Empresarial' styles='fs-md-30'>
                <ul className='list-unstyled'>
                  <li>Fundamental para a solidez do negócio este seguro protege sua empresa contra danos ao prédio e ao seu conteúdo, como máquinas, móveis, utensílios, mercadorias e matérias-primas, independentemente do tamanho ou segmento que atua, garantindo que, em caso de imprevistos, as atividades do seu negócio não sejam prejudicadas.</li>
                </ul>
              </SectionTopics>
              <SectionTopics title='Garantias' styles='fs-md-30'>
                <ul className='list-unstyled'>
                  <li>Têm como objetivo assegurar o fiel cumprimento de contratos privados, públicos ou licitações nos prazos e custos previstos. Apresenta diversas vantagens em relação à fiança bancária. É oferecido em diversas modalidades, como as citadas abaixo e outras.</li>
                  <li>Performance: garante o cumprimento de obrigações contratuais de construção, fornecimento ou prestação de serviço. Fornece credibilidade ao processo e facilita a negociação entre contratante e contratado.</li>
                  <li>Permuta imobiliária: melhor mecanismo para aquisição de terrenos, pois não há necessidade de investimento inicial.</li>
                  <li>Judicial: substituição de depósitos judiciais cíveis, trabalhistas e tributários.</li>
                </ul>
              </SectionTopics>
              <img className='img-fluid' src={Seguros} alt='Tabela Seguros PJ' />
              <SectionTopics title='Risco de Engenharia' styles='fs-md-30'>
                <ul className='list-unstyled'>
                  <li>Garante ao construtor a cobertura em casos de acidentes súbitos e imprevistos durante a execução de obras civis, na instalação e montagem de máquinas e equipamentos. Oferece cobertura de danos materiais e corporais causados a terceiros bem como para funcionários das empreiteiras e subempreiteiras que fazem parte da execução da obras.</li>
                </ul>
              </SectionTopics>
              <SectionTopics title='Saúde Empresarial' styles='fs-md-30'>
                <ul className='list-unstyled'>
                  <li>A partir de 10 vidas: Cada vez mais populares entre os benefícios oferecidos pelas empresas o seguro saúde oferece serviços de assistência médica diferenciados, com maior ou menor abrangência, conforme contratação. Cobertura em rede credenciada ou reembolso de despesas médico-hospitalares e livre escolha de médicos e hospitais.</li>
                </ul>
              </SectionTopics>

            </div>
          </div>
        </div>
      </section>
      <div className='container pb-4'>
        <SideBar className='col-12 col-lg-4 px-0 px-lg-3'>
          <div className='position-sticky'>
            <CallToAction
              section='dobra_2'
              elementAction='click button'
              elementName='Abra sua conta e contrate'
              sectionName='Segurança é essencial para o seu negócio'
              text='Abra sua conta e contrate'
              chat='true'
              PjAccount
            />
          </div>
        </SideBar>
      </div>
    </ContentWrapper>
  )
}
