import React from 'react'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'
import { Content } from './sections/Content/_index'
import { Hero } from './sections/Hero/_index'
import { Wrapper } from './style'

const CanaisDeAtendimento = () => {
  return (
    <Wrapper>
      <Layout isPJ pageContext={pageContext}>
        <Hero />
        <Content />
      </Layout>
    </Wrapper>
  )
}

export default CanaisDeAtendimento
